declare global {
  interface Window {
    turnstile: any
  }
}

export function useTurnstile(elementRef: Ref<HTMLElement | null | undefined>) {
  const sitekey = useRuntimeConfig().public.turnstile?.clientId ?? ''

  return {
    challenge: () => {
      return new Promise<string>((resolve, reject) => {
        if (import.meta.client) {
          const element = elementRef.value
          const turnstile = window.turnstile
          if (element && turnstile) {
            turnstile.ready(() => {
              turnstile.render(element, {
                'sitekey': import.meta.dev ? '1x00000000000000000000AA' : sitekey,
                'callback': (token: string) => {
                  resolve(token)
                },
                'error-callback': (errorCode: string) => {
                  reject(new Error(`Challenge Error ${errorCode}`))
                },
              })
            })
          }
          else {
            reject(new Error('No element or turnstile'))
          }
        }
        else {
          reject(new Error('Not client'))
        }
      })
    },
  }
}
